import { NavigationItem } from '@af/design-system-docs-ui';

// @ts-expect-error -- untyped JS file
import { PAGE_ORDER } from '../../../constellation.config';

import getPackagePages from './get-package-pages';
import { ContentfulData } from './types';

export const getNavigationItems = (data: ContentfulData): NavigationItem[] => [
	{
		item: { title: 'Home', to: '', slug: '' },
		subitems: [],
	},
	{
		item: { title: "What's new", to: '/whats-new', slug: 'whats-new' },
		subitems: [],
	},
	{
		item: { title: 'Get started', to: '/get-started', slug: 'get-started' },
		subitems: [
			{
				item: {
					title: 'Design',
					to: '/get-started/design',
					slug: 'design',
				},
				subitems: [],
			},
			{
				item: {
					title: 'Develop',
					to: '/get-started/develop',
					slug: 'develop',
				},
				subitems: [
					{
						item: {
							title: 'Composing code',
							to: '/get-started/develop/composition',
							slug: 'composition',
						},
						subitems: [],
					},
				],
			},
			{
				item: {
					title: 'Content design',
					to: '/get-started/content-design',
					slug: 'content-design',
				},
				subitems: [],
			},
			{
				item: {
					title: 'Figma libraries',
					to: '/get-started/figma-libraries',
					slug: 'figma-libraries',
				},
				subitems: [],
			},
			{
				item: {
					title: 'Vision, values, and principles',
					to: '/get-started/vision-values-principles',
					slug: 'vision-values-principles',
				},
				subitems: [],
			},
			{
				item: {
					title: 'Release phases',
					to: '/get-started/release-phases',
					slug: 'release-phases',
				},
				subitems: [],
			},
		],
	},
	{
		item: { title: 'Foundations', to: '/foundations', slug: 'foundations' },
		subitems: [
			{
				item: { title: 'Tokens', to: '/foundations/tokens', slug: 'tokens' },
				subitems: [
					{
						item: {
							title: 'All design tokens',
							to: '/components/tokens/all-tokens',
							slug: 'all-tokens',
						},
						subitems: [],
					},
					{
						item: {
							title: 'Design tokens explained',
							to: '/tokens/design-tokens',
							slug: 'design-tokens',
						},
						subitems: [],
					},
					{
						item: {
							title: 'Use tokens in code',
							to: '/tokens/use-tokens-in-code',
							slug: 'use-tokens-in-code',
						},
						subitems: [],
					},
					{
						item: {
							title: 'Use tokens in design',
							to: '/tokens/use-tokens-in-design',
							slug: 'use-tokens-in-design',
						},
						subitems: [],
					},
					{
						item: {
							title: 'Migrate to tokens',
							to: '/tokens/migrate-to-tokens',
							slug: 'migrate-to-tokens',
						},
						subitems: [],
					},
				],
			},
			{
				item: {
					title: 'Accessibility',
					to: '/foundations/accessibility',
					slug: 'accessibility',
				},
				subitems: [],
			},
			{
				item: { title: 'Content', to: '/foundations/content', slug: 'content' },
				subitems: [
					{
						item: {
							title: 'Inclusive language',
							to: '/content/inclusive-writing',
							slug: 'inclusive-writing',
						},
						subitems: [],
					},
					{
						item: {
							title: 'Language and grammar',
							to: '/content/language-and-grammar',
							slug: 'language-and-grammar',
						},
						subitems: [],
					},
					{
						item: {
							title: 'Voice and tone',
							to: '/content/voice-tone',
							slug: 'voice-tone',
						},
						subitems: [],
					},
					{
						item: {
							title: 'Date and time',
							to: '/content/date-time',
							slug: 'date-time',
						},
						subitems: [],
					},
					{
						item: {
							title: 'Designing messages',
							to: '/content/designing-messages',
							slug: 'designing-messages',
						},
						subitems: [
							{
								item: {
									title: 'Empty state',
									to: '/content/designing-messages/empty-state',
									slug: 'empty-state',
								},
								subitems: [],
							},
							{
								item: {
									title: 'Error message',
									to: '/content/designing-messages/writing-error-messages',
									slug: 'writing-error-messages',
								},
								subitems: [],
							},
							{
								item: {
									title: 'Feature discovery',
									to: '/content/designing-messages/feature-discovery',
									slug: 'feature-discovery',
								},
								subitems: [],
							},
							{
								item: {
									title: 'Info messages',
									to: '/content/designing-messages/writing-an-info-message',
									slug: 'writing-an-info-message',
								},
								subitems: [],
							},
							{
								item: {
									title: 'Success message',
									to: '/content/designing-messages/writing-a-success-message',
									slug: 'writing-a-success-message',
								},
								subitems: [],
							},
							{
								item: {
									title: 'Warning message',
									to: '/content/designing-messages/writing-a-warning-message',
									slug: 'writing-a-warning-message',
								},
								subitems: [],
							},
						],
					},
				],
			},
			{
				item: { title: 'Spacing', to: '/foundations/spacing', slug: 'spacing' },
				subitems: [
					{
						item: {
							title: 'Layout primitives',
							to: '/foundations/spacing/primitives',
							slug: 'primitives',
						},
						subitems: [],
					},
				],
			},
			{
				item: {
					title: 'Grid',
					to: '/foundations/grid-beta',
					status: { type: 'beta' },
					slug: 'grid-beta',
				},
				subitems: [
					{
						item: {
							title: 'Applying Grid',
							to: '/foundations/grid-beta/applying-grid',
							status: { type: 'beta' },
							slug: 'applying-grid',
						},
						subitems: [],
					},
				],
			},
			{
				item: { title: 'Color', to: '/foundations/color-new', slug: 'color-new' },
				subitems: [
					{
						item: { title: 'Accents', to: '/foundations/color-new/accents', slug: 'accents' },
						subitems: [],
					},
					{
						item: {
							title: 'Color picker swatches',
							to: '/foundations/color-new/color-picker-swatches',
							slug: 'color-picker-swatches',
						},
						subitems: [],
					},
					{
						item: {
							title: 'Data Visualization',
							to: '/foundations/color-new/data-visualization-color',
							slug: 'data-visualization-color',
						},
						subitems: [],
					},
					{
						item: {
							title: 'Color palette',
							to: '/foundations/color-new/color-palette-new',
							slug: 'color-palette-new',
						},
						subitems: [],
					},
				],
			},
			{
				item: {
					title: 'Typography',
					to: '/foundations/typography',
					slug: 'typography',
					status: { type: 'beta' },
				},
				subitems: [
					{
						item: {
							title: 'Applying typography',
							to: '/foundations/typography/applying-typography',
							slug: 'applying-typography',
							status: { type: 'beta' },
						},
						subitems: [],
					},
					{
						item: {
							title: 'Product typefaces and scale',
							to: '/foundations/typography/product-typefaces-and-scale',
							status: { type: 'beta' },
							slug: 'product-typefaces-and-scale',
						},
						subitems: [],
					},
				],
			},
			{
				item: {
					title: 'Iconography',
					to: '/foundations/iconography',
					status: { type: 'beta' },
					slug: 'iconography',
				},
				subitems: [
					{
						item: {
							title: 'Iconography (legacy)',
							to: '/foundations/iconography/iconography-legacy',
							slug: 'iconography-legacy',
						},
						subitems: [],
					},
				],
			},
			{
				item: {
					title: 'Illustrations',
					to: '/foundations/illustrations',
					slug: 'illustrations',
				},
				subitems: [],
			},
			{
				item: { title: 'Logos', to: '/foundations/logos', slug: 'logos' },
				subitems: [],
			},
			{
				item: { title: 'Elevation', to: '/foundations/elevation', slug: 'elevation' },
				subitems: [],
			},
		],
	},
	{
		item: { title: 'Components', to: '/components', slug: 'components' },
		// TODO: Replace this with code-genned file system routes as we migrate component documentation into the service
		subitems: getPackagePages({
			categories: data.allWorkspaceInfo.categories,
			componentPages: data.allSitePage.componentPages,
		}),
	},
	{
		item: { title: 'Tools', to: '/tools', slug: 'tools' },
		subitems: [],
	},
	{
		item: { title: 'Contact us', to: '/contact-us', slug: 'contact-us' },
		subitems: [],
	},
];
